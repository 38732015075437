<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Role
        //- b-col(md="4")
          b-form-group(:label="$t('label.role')", :label-for="$t('label.role')", description="")
            validation-provider(:name="$t('label.role')", rules="required", v-slot="{ errors }")
              v-select#roles(
                v-model="data.role",
                :reduce="(roles) => roles.value",
                :multiple="isSelectMultiple",
                :close-on-select="!isSelectMultiple"
                label="title",
                :options="roles",
                :placeholder="$t('placeholder.role')"
              )
              small(class="text-danger") {{ errors[0] }}
        
        // Evaluated
        b-col(md="4")
          b-form-group(:label="$t('label.evaluated')", :label-for="$t('label.evaluated')", description="")
            validation-provider(:name="$t('label.evaluated')", rules="required", v-slot="{ errors }")
              v-select#evaluated(
                v-model="data.evaluated",
                :reduce="(worker) => worker.value",
                :options="workersSupervised",
                :placeholder="$t('placeholder.select_evaluado')",
                label="title"
              )
              small(class="text-danger") {{ errors[0] }}

        // Process
        b-col(md="4")
          b-form-group(:label="$t('label.process')", :label-for="$t('label.process')", description="")
            validation-provider(:name="$t('label.process')", rules="required", v-slot="{ errors }")
              v-select#processes(
                v-model="data.process",
                :reduce="(processes) => processes.value",
                :multiple="isSelectMultiple",
                :close-on-select="!isSelectMultiple"
                label="title",
                :options="processes",
                :placeholder="$t('placeholder.process')"
              )
              small(class="text-danger") {{ errors[0] }}

        // Confirmations per year
        b-col(md="4")
          b-form-group(:label="$t('label.confirmationsPerYear')", :label-for="$t('label.confirmationsPerYear')", description="")
            validation-provider(:name="$t('label.confirmationsPerYear')", rules="required|integer|min_value:0", v-slot="{ errors }")
              b-form-input#confirmationsPerYear(
                v-model="data.confirmationsPerYear",
                type="number"
                :placeholder="$t('placeholder.confirmationsPerYear')"
              )
              small(class="text-danger") {{ errors[0] }}

      b-row
        // Submit
        b-col.text-right(cols="12")
          b-button(variant="primary", :disabled="isSubmitting", @click="validateAndSubmit")
            | {{ $t('message.submit') }}
</template>

<script>
import vSelect from 'vue-select'
import { onMounted, ref, toRefs } from '@vue/composition-api/dist/vue-composition-api'
import useCommon from '../../useCommon'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, integer, min_value } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'

export default {
  name: 'Form',

  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  props: ['data', 'isSubmitting', 'isSelectMultiple'],

  setup(props, { emit }) {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { getProcessesForDropDown, processes, getWorkersSupervisedForDropDown, workersSupervised } = useCommon()
    const form = ref(null)

    const validateForm = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateForm()
      .then(() => emit('submit', toRefs(props.data)))
      .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    onMounted(() => {
      // getRolesForDropDown()
      getProcessesForDropDown()
      getWorkersSupervisedForDropDown()
    })

    return {
      // roles,
      processes,
      required,
      integer,
      min_value,
      form,
      validateAndSubmit,
      workersSupervised,
    }
  },
}
</script>

<style scoped>
</style>
